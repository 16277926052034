<template>
  <div tabs-horizontal>
    <a href="" v-for="(tab, t) in props.tabs" :class="[btnClass(tab)]" v-text="tab" @click.prevent="onTabClick(tab)" :key="t"></a>
  </div>
</template>
<style src="~/assets/scss/section/tabs-horizontal.scss" lang="scss"></style>
<script setup>
const selected = defineModel();

const props = defineProps({
  tabs: {
    type: Object,
    required: true,
  },
});

const btnClass = (tab) => {
  const classes = ["btn"];

  if (tab === selected.value) classes.push("btn-outline-primary active");
  else classes.push('btn-link-dark')
  return classes;
};

const onTabClick = (tab) => {
  selected.value = tab;
}
</script>
